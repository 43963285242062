import { getFormattedBeginDate, getFormattedEndDate } from "@/common/dateHelper"
import { ProxyBase } from "./common"
import {
    AccrualData,
    AccrualGroup,
    AccrualTariffGroup, AccrualTariffZoneGroup,
    BillingData,
    MutualSettlementData,
    PaymentDetail,
    PaymentType
} from "./dataContracts/personalAccount"

export class BillingPresenterProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "BillingPresenter/", token, tokenType)
    }

    public getMutualSettlement(contractId: string, period: Date): Promise<MutualSettlementData> {
        return this.getDataFromAction<MutualSettlementData>("GetMutualSettlement", {
            contractId,
            period: getFormattedBeginDate(period),
        })
    }

    public getMutualSettlements(contractId: string, beginDate: Date, endDate: Date): Promise<MutualSettlementData[]> {
        return this.getDataFromAction<MutualSettlementData[]>("GetMutualSettlements", {
            contractId,
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        })
    }

    public getPaymentData(contractId: string, period: Date): Promise<PaymentDetail[]> {
        return this.getDataFromAction<PaymentDetail[]>("GetPaymentData", {
            contractId,
            period: getFormattedBeginDate(period),
        })
    }

    public getPayments(contractId: string, type: PaymentType, beginDate: Date, endDate: Date): Promise<PaymentDetail[]> {
        return this.getDataFromAction<PaymentDetail[]>("GetPayments", {
            contractId,
            type: type.toString(),
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        })
    }

    public getBillingData(contractId: string, period: Date): Promise<BillingData> {
        return this.getDataFromAction<BillingData>("GetBillingData", {
            contractId,
            period: getFormattedBeginDate(period),
        })
    }

    public getLastAccruals(contractId: string): Promise<AccrualGroup> {
        return this.getDataFromAction<AccrualGroup>("GetLastAccruals", { contractId })
    }

    public getLastMutualSettlement(contractId: string): Promise<MutualSettlementData> {
        return this.getDataFromAction<MutualSettlementData>("GetLastMutualSettlement", { contractId })
    }
}