import { TariffZone } from "../dataContracts"

export type ReceiptOptions = {
    contractId: string,
    email?: string,
    sendByEmail: boolean,
    sendOnPaper: boolean
}

export type PersonalAccountInfo = {
    contractInfo: ContractInfo,
    pointsInfo: PointInfo[]
}

export type ContractInfo = {
    registeredCount: number,
    areaOfPremises: number,
    benefitsCount: number,
    companyName: string
}

export type PointInfo = {
    id: string,
    name: string,
    buildingName: string,
    intervalAccounting: boolean,
    meteringsPeriodEnd: number,
    meteringsPeriodBegin: number,
    meterDeviceInfo: MeterDeviceInfo,
    calculationMethod: PointCalculationMethod,
    lastMeterings: MeteringData[],
}

export enum PointCalculationMethod {
    Indications,
    Standard,
    SubstituteInformation,
    FixedFlow,
    ByPower
}


export type MeterDeviceInfo = {
    id: string,
    number: string,
    scalesCount: number,
    digitsCount: number,
    type: string
}

export type MeteringData = {
    tariffZone: TariffZone,
    value: number,
    date: string,
}

export type PaymentDocument = {
    id: string,
    contractId: string,
    documentDate: string,
    paymentDocumentFiles: PaymentDocumentFile[]
}

export type PaymentDocumentFile = {
    fileId: string,
    fileType: PaymentDocumentFileType
}

export enum PaymentDocumentFileType {
    ReceiptSubscriber,
    UTD,
    FinalInvoice,
    IntermediatePaymentAccount
}

export type Indication = {
    date: string,
    value: number,
    tariffZone: TariffZone,
    source: MeteringSource,
    state?: IndicationState,
    hourly: boolean
}

export enum IndicationState {
    New,
    Processed
}

export enum IndicationType {
    Accepted,
    Transferred
}

export type MeterDeviceData = {
    id: string,
    type: string,
    serialNumber: string
}

export enum MeteringSource {
    Consumer,
    PublicContractor,
    ResourceOrganization,
    NetworkOrganization,
    ReplacementInformation
}

export type MutualSettlementData = {
    contractID: string,
    period: string,
    electricityBilled: number,
    contractPenaltiesBilled: number,
    legalPenaltiesBilled: number,
    taxBilled: number,
    paid: number,
    electricityDebt: number,
    contractPenaltiesDebt: number,
    legalPenaltiesDebt: number,
    taxDebt: number,
    compensation: number | null
}

export type BillingData = {
    contractID: string,
    period: string,
    accruals: AccrualData[]
}

export type AccrualData = {
    tariffZone: TariffZone,
    accrualType: AccrualType,
    range: AccrualRange | null,
    quantity: number,
    price: number | null,
    amount: number
}

export enum AccrualType {
    OwnConsumption,
    Losses,
    HouseConsumption,
    Recalculation,
    Benefits,
    BenefitsRecalculation
}

export enum AccrualRange {
    Empty,
    First,
    Second,
    Third
}

export type AccrualTypeGroup = {
    accrualType: AccrualType
    range: AccrualRange | null
    accrualData: AccrualData[]
}

export type AccrualTariffGroup = {
    tariffZone: TariffZone
    range: AccrualRange | null
    price: number | null
}

export type AccrualTariffZoneGroup = {
    tariffZone: TariffZone
    quantity: number
    amount: number
}

export type AccrualGroup = {
    period: string,
    accrualTariffGroups: AccrualTariffGroup[],
    accrualTariffZoneGroups: AccrualTariffZoneGroup[]
}

export type PaymentDetail = {
    date: string,
    amount: number,
    source: string,
    state: PaymentState
}

export const getPaymentStatus = (status: PaymentState): string => {
    switch (status) {
        case PaymentState.New: return 'Обрабатывается'
        case PaymentState.Paid: return 'Обработан'
        case PaymentState.Notified: return 'Обработан'
        case PaymentState.Checked: return 'Обработан'
        case PaymentState.Fail: return 'Ошибка'
        case PaymentState.Canceled: return 'Отменён'
    }
}

export enum PaymentType {
    All,
    Lk
}

export const getAccrualTypeName = (zone: AccrualType): string => {
    switch (zone) {
        case AccrualType.OwnConsumption: return 'Cобственное потребление'
        case AccrualType.Losses: return 'Потери'
        case AccrualType.HouseConsumption: return 'Общедомовое потребление'
        case AccrualType.Recalculation: return 'Перерасчеты'
        case AccrualType.Benefits: return 'Льготы'
        case AccrualType.BenefitsRecalculation: return 'Перерасчеты льгот'
        default: throw new Error()
    }
}

export const getAccrualRangeName = (range: AccrualRange | null): string => {
    switch (range) {
        case AccrualRange.Empty: return '0 диапазон';
        case AccrualRange.First: return '1 диапазон';
        case AccrualRange.Second: return '2 диапазон';
        case AccrualRange.Third: return '3 диапазон';
        default: return '';
    }
}

export type PaymentRequestData = {
    contractId: string,
    amount: number,
    returnUrl: string,
    paymentMethod: PaymentMethod
}

export enum PaymentMethod {
    Sber,
    Sbp,
    Vbrr
}

export enum PaymentOrderType {
    Sbp,
    Sber,
    Vbrr
}

export enum PaymentState {
    New,
    Paid,
    Notified,
    Checked,
    Fail,
    Canceled
}

export type PaymentOrderData = {
    order: number,
    paymentType: PaymentOrderType,
    paymentState: PaymentState,
    createdUtc: string,
    amount: number
}

export type SberResponseData = {
    orderId: string,
    formUrl: string,
    orderNumber: number
}

export type SbpResponseData = {
    QR_ID: string,
    QR_DATA: string,
    QR_IMG_REF: string,
    QR_PSB_ID: string,
    QR_IMG_DATA: string,
    orderNumber: number
}