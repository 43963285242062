export enum AppRouteNames {
  Home = "Home",
  Welcome = "Welcome",
  FirstLogin = "FirstLogin",
  ManagementObject = "ManagementObject",
  PersonalAccount = "PersonalAccount",
  UserProfile = "UserProfile",
  Appeals = "Appeals",
  Notices = "Notices",
  Root = "Root"
}

const routes: Record<AppRouteNames, string> = {
    [AppRouteNames.Root]: "/",
    [AppRouteNames.Home]: "/isu",
    [AppRouteNames.Welcome]: "/welcome",
    [AppRouteNames.FirstLogin]: "/first-login",
    [AppRouteNames.ManagementObject]: "/management-object",
    [AppRouteNames.UserProfile]: "/user-profile",
    [AppRouteNames.PersonalAccount]: "/personal-area",
    [AppRouteNames.Appeals]: "/appeals",
    [AppRouteNames.Notices]: "/notices",
};

export const getPath = (route: AppRouteNames) =>
    routes[route] || "Route not found";